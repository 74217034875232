<template>
  <div v-if="accept">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div>
          <b-button
            v-for="ev in evtList"
            :key="ev.id"
            variant="primary"
            style="margin: 2px 2px;"
            :href="`/congress/${ev.event.congress.path_event}/${ev.event.slug}`"
          >
            Transmissão das {{ ev.event.start_time }}
          </b-button>
        </div>
        <!-- VMIX -->
        <div v-if="eventSpeaker.password_vmix && sessionInfo.user.role === 'SPEAKER'">
          <div v-if="!(sameDevice)">
            Conectando em {{ 10 - timer }} ...
          </div>
          <iframe
            v-if="sameDevice"
            :src="`https://www.vmixcall.com/call.aspx?Key=${eventSpeaker.password_vmix}&Name=${eventSpeaker.speaker.name_badge}`"
            width="100%"
            height="500"
            allow="camera;microphone;fullscreen"
            style="border:none;"
            allowfullscreen=""
          />
        </div>
        <!-- IFRAMES -->
        <!-- Falta o link2 -->
        <div v-else>
          <div v-if="live.link2 === null">
            <div
              v-if="live !== null"
            >
              <iframe
                v-if="live.type_link === 'cloudflare'"
                id="cloudflare"
                :src="vimeo_base + link + control"
                style="left: 0px; width: 100%; border: 0"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
                loop="true"
              />
              <vue-plyr
                v-if="live.type_link === 'youtube'"
                :options="options"
              >
                <div
                  data-plyr-provider="youtube"
                  :data-plyr-embed-id="link"
                  allow="autoplay"
                />
              </vue-plyr>
              <iframe
                v-if="live.type_link === 'vimeo'"
                :src="'https://player.vimeo.com/video/'+live.link+'?autoplay=true&loop=false&byline=false&portrait=false&title=false&speed=true'"
                frameborder="0"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;"
                allowfullscreen
                style="overflow:hidden;height:800px;width:100%"
                height="800px"
                width="100%"
              />
            </div>
          </div>
          <div v-else>
            <div
              style="position: relative;"
              @keydown.left="exitFull"
              @keydown.right="exitFull"
            />
            <div
              id="divfull"
            >
              <div
                id="base"
                refs="divfull"
              >
                <!-- <div id="bg"></div> -->
                <div
                  id="bg1"
                  @dblclick="toggle(true)"
                />
                <div id="header">
                  <p id="title-text">
                    {{ live.event.title.toUpperCase() }}
                  </p>
                </div>
                <!--
                  <div id="sub-header">
                    <p>SUB-TITULO</p>
                  </div>
                  -->

                <div id="principal">
                  <vimeo-player
                    ref="playerOne"
                    :data-vimeo-id="videoID"
                    :player-heigth="heightTwo"
                    :options="optionsOne"
                    @ready="onReady"
                    @click="playStop"
                  />
                  <!-- <div
                    id="sobras"
                    class="vertical-layout"
                  /> -->
                </div>

                <fullscreen
                  id="secundario"
                  v-model="fullscreen"
                  :teleport="teleport"
                  :page-only="pageOnly"
                  @click="playStop"
                >
                  <div class="fullscreen-wrapper">
                    <vimeo-player
                      ref="playerTwo"
                      :data-vimeo-id="videoIDtWO"
                      :options="optionsTwo"
                      style="width: 100%; max-width: 1920px;"
                      @ready="onReady"
                      @click="playStop"
                    />
                    <div
                      v-if="fullscreen"
                      id="controllToggle"
                    >
                      <div>
                        <button
                          v-if="!playVideo"
                          class="btn btn-primary"
                          style="margin-right:10px;height: 39px;"
                          @click="playStop"
                        >
                          <feather-icon
                            color="#fff"
                            icon="PlayIcon"
                            size="15"
                          />
                        </button>

                        <button
                          v-else
                          class="btn btn-danger"
                          style="margin-right:10px;height: 39px;"
                          @click="playStop"
                        >
                          <feather-icon
                            color="#fff"
                            icon="PauseIcon"
                            size="15"
                          />
                        </button>
                      </div>

                      <input
                        id="customRange2"
                        v-model="timePlayerCurrent"
                        type="range"
                        class="form-range"
                        min="0"
                        :max="liveTime"
                        style="top:35%; width: 100%;"
                      >
                      <button
                        v-if="!fullscreen"
                        type=" button "
                        class="btn btn-success"
                        style="margin-left:10px;height: 39px;"
                        @click="toggle(true)"
                      >
                        <feather-icon
                          color="#000"
                          icon="Maximize2Icon"
                          size="15"
                        />
                      </button>

                      <button
                        v-else
                        type=" button "
                        class="btn btn-success"
                        style="margin-left:10px;height: 39px;"
                        @click="toggle(false)"
                      >
                        <feather-icon
                          color="#000"
                          icon="Minimize2Icon"
                          size="15"
                        />
                      </button>
                    </div>
                    <!--
          <img v-show="!fullscreen" :src="'//picsum.photos/640/360?random=1'">
          <img v-show="fullscreen" :src="'//picsum.photos/1280/720?random=1'">
          -->
                  </div>
                </fullscreen>

                <!--
                    <div id="mask" @dblclick="exitFull" @click="playStop">
                    </div>
                    -->
                <div
                  id="controll"
                  style="display: flex; flex-direction:row;"
                >
                  <div>
                    <button
                      v-if="!playVideo"
                      class="btn btn-primary"
                      style="margin-right:10px;height: 39px;"
                      @click="playStop"
                    >
                      <feather-icon
                        color="#fff"
                        icon="PlayIcon"
                        size="15"
                      />
                    </button>

                    <button
                      v-else
                      class="btn btn-danger"
                      style="margin-right:10px;height: 39px;"
                      @click="playStop"
                    >
                      <feather-icon
                        color="#fff"
                        icon="PauseIcon"
                        size="15"
                      />
                    </button>
                  </div>

                  <input
                    id="customRange2"
                    v-model="timePlayerCurrent"
                    type="range"
                    class="form-range"
                    min="0"
                    :max="liveTime"
                    style="top:35%; width: 100%;"
                  >
                  <button
                    class="btn btn-success"
                    style="margin-left:10px;height: 39px;"
                    @click="toggle(true)"
                  >
                    <feather-icon
                      color="#000"
                      icon="Maximize2Icon"
                      size="15"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- PALESTRANTES -->
        <b-card>
          <div v-if="speakers.length > 0">
            <CarouselInterval :speakers="speakers" />
          </div>
        </b-card>
      </div>
      <div class="col-lg-4 col-md-12">
        <chat
          :live-id="live_id"
          :user-id="sessionInfo.user.id"
          style="margin:0px;z-index: 999999;"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { BLink } from 'bootstrap-vue'
import chat from '../../../components/chat/Chat.vue'
import CarouselInterval from '@/components/carousel/CarouselInterval.vue'
import { mapActions } from 'vuex'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import VueFullscreen from 'vue-fullscreen'
import Vue from 'vue'

Vue.use(VueFullscreen)

export default {
  components: {
    CarouselInterval,
    chat,
    // BLink,
  },
  data() {
    return {
      show: false,
      fullscreen: false,
      teleport: false,
      pageOnly: false,
      deviceId: null,
      sameDevice: false,
      timer: 0,
      timePlayerCurrent: 0,
      accept: false,
      speakers: [],
      live_id: null,
      vimeo_base: 'https://iframe.videodelivery.net/',
      control: '?preload=true&loop=true&autoplay=true',
      link: null,
      live: null,
      videoID: null,
      videoIDtWO: null,
      heightOne: 200,
      heightTwo: 500,
      optionsOne: {
        muted: false,
        autoplay: false,
        controls: false,
        responsive: true,
      },
      optionsTwo: {
        muted: true,
        autoplay: false,
        controls: false,
        responsive: true,
      },
      playerReady: false,
      evtList: [],
      verifyDevice: false,
      options: {
        autoplay: true,
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: null,
        },
      },
      time_in_live: 0,
      youtube_base: 'https://www.youtube.com/embed/',
      youtube_config: '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0',
      playVideo: false,
      liveTime: 0
    }
  },
  computed: {
    congress() {
      return this.$store.state.congress.congress
    },
    event() {
      return this.$store.state.congress.event
    },
    eventSpeaker() {
      return this.$store.state.congress.eventSpeaker
    },
    device() {
      return this.$store.state.congress.deviceId
    },
    sessionInfo() {
      return this.$store.state.auth.sessionInfo
    },
    speakerEvent() {
      return this.$store.state.congress.speakerEvents
    }
  },
  watch: {
    timePlayerCurrent() {
      this.$refs.playerOne.player.setCurrentTime(this.timePlayerCurrent)
      this.$refs.playerTwo.player.setCurrentTime(this.timePlayerCurrent)
    },
    async event() {
      if (this.event) {
        if (this.sessionInfo.user.role === 'SPEAKER') {
          await this.ActionGetEventSpeakerByEvent({
            event_id: this.event.id,
            device_id: this.deviceId
          })
          if (typeof this.eventSpeaker === 'object') {
            if (this.eventSpeaker.password_vmix) {
              // check device id
              this.verifyDevice = true
              if (this.deviceId === this.eventSpeaker.device_id) {
                this.sameDevice = true
                this.accept = true
              } else {
                this.sameDevice = false
                this.$bvModal
                  .msgBoxConfirm('Caso você abra esta mesma tela em outro dispositivo, a janela de vídeo poderá ser desconectada automaticamente.')
                  .then(value => {
                    if (value) {
                    // load the speaker vmix
                    // update device id
                      this.ActionGetDeviceId({
                        event_id: this.event.id,
                        device_id: this.deviceId
                      })
                      this.accept = true
                      this.sameDevice = false
                    } else {
                      window.location.href = '/'
                    }
                  })
              }
            } else {
              this.accept = true
            }
          } else {
            this.accept = true
          }
        } else {
          this.accept = true
        }
        const live = await this.ActionGetLiveInfo({ slug: this.event.slug })
        console.log('start live')
        console.log(live)
        console.log('end live')
        this.live = live
        this.live_id = live.id
        this.link = live.link
        this.videoID = live.link2 // link2
        this.videoIDtWO = live.link // link
        this.speakers = this.event.speakers
        // monitoring
        this.createLiveMonitoring()
      }
    },
    device() {
      if (this.deviceId === this.device) {
        this.sameDevice = true
      } else {
        this.sameDevice = false
      }
    },
    speakerEvent() {
      // eslint-disable-next-line no-alert

      const q = new Date()
      const m = q.getMonth() + 1
      const d = q.getDate()
      const y = q.getFullYear()

      const date = new Date(`${y}-${m}-${d}`)

      this.speakerEvent.forEach(element => {
        const evtDate = new Date(element.event.date)

        if (element.event.slug !== this.$route.params.sluglive && evtDate.toString() === date.toString()) {
          this.evtList.push(element)
        }
      })
    },
    eventSpeaker() {
      // console.log(this.eventSpeaker)
    }
  },
  async mounted() {
    window.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.toggle(false)
      }
    })
    this.LoadEvent()
    this.ActionGetEventBySpeaker()

    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      this.time_in_live++
    }, 1000)

    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      this.timer++
      if (this.timer === 10 && this.sessionInfo.user.role === 'SPEAKER' && this.verifyDevice === true) {
        this.ActionGetDeviceId({
          event_id: this.event.id,
        })
        this.timer = 0
      }
    }, 1000)

    const fpPromise = FingerprintJS.load();
    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise
      const result = await fp.get()

      // This is the visitor identifier:
      const { visitorId } = result
      this.deviceId = visitorId
    })()

    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      if (this.time_in_live === 12) {
        this.ActionLiveMonitoringUpdate({
          user_id: this.sessionInfo.user.id,
          live_id: this.live.id,
          seconds_in_live: this.time_in_live
        })
        this.time_in_live = 0
      }
    }, 1000)
    setInterval(async () => {
      // // eslint-disable-next-line no-plusplus
      this.liveTime = await this.$refs.playerTwo.player.getDuration()
      this.timePlayerCurrent = await this.$refs.playerTwo.player.getCurrentTime()
    }, 1000)
  },
  methods: {
    ...mapActions('congress', [
      'ActionLoadCongress',
      'ActionLoadEvent',
      'ActionGetEventSpeakerByEvent',
      'ActionGetDeviceId',
      'ActionGetLiveInfo',
      'ActionGetEventBySpeaker'
    ]),
    ...mapActions('congressAdministrator', [
      'ActionLiveMonitoringCreate',
      'ActionLiveMonitoringUpdate'
    ]),
    toggle(fullscreen) {
      this.fullscreen = fullscreen
    },
    async syncTime() {
      await this.sleep(500)
      const time = await this.$refs.playerOne.player.getCurrentTime()
      this.$refs.playerOne.player.setCurrentTime(time)
      this.$refs.playerTwo.player.setCurrentTime(time)
      console.log('syncTime')
    },
    async exitFull() {
      const secundario = document.getElementById('secundario')
      const mask = document.getElementById('mask')
      if (mask.classList.value !== 0) {
        secundario.classList.remove('fullScreen')
        mask.classList.remove('fullScreen')
      }
      this.fullscreen = !this.fullscreen
    },
    async onFull() {
      document.getElementsByTagName('iframe')[1].requestFullscreen()
    },
    toggleApi() {
      this.$fullscreen.toggle()
    },
    full() {
      this.$refs.playerOne.$parent.full()
      /*
      const div = document.getElementById('secundario')
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (div.requestFullScreen) {
          div.requestFullScreen()
        } else if (div.mozRequestFullScreen) {
          div.mozRequestFullScreen()
        } else if (div.webkitRequestFullScreen) {
          div.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
      */
    },
    async playStop() {
      if (this.playVideo === false) {
        await this.$refs.playerOne.play()
        this.$refs.playerTwo.play()
        this.playVideo = true
      } else {
        this.$refs.playerOne.pause()
        this.$refs.playerTwo.pause()
        this.playVideo = false
      }
    },
    async swape() {
      const time = this.$refs.playerOne.player.getCurrentTime()
      const temp = this.videoID
      this.videoID = this.videoIDtWO
      this.videoIDtWO = temp
      if (this.displaySecondWebcam === false) {
        this.displaySecondWebcam = true
      } else {
        this.displaySecondWebcam = false
      }
      await this.sleep(1000)
      this.$refs.playerOne.play()
      this.$refs.playerTwo.play()
      this.$refs.playerOne.player.setCurrentTime(time)
      this.$refs.playerTwo.player.setCurrentTime(time)
      this.playVideo = true
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    /*
    play() {
      const player_second = document.getElementById('second_player')
      const player_principal = document.getElementById('principal_player')
      const player = new Vimeo.Player(player_second)

      player.on('play')
      player_principal.on('play')
    },
    */
    onReady() {
      this.playerReady = true
    },
    async createLiveMonitoring() {
      this.body = {
        user_id: this.sessionInfo.user.id,
        live_id: this.live.id,
      }

      const api = await this.ActionLiveMonitoringCreate(this.body)
    },
    updateLiveMonitoringTime() {
      const u = this.sessionInfo.user.id
      const l = this.live.id
      const s = this.time_in_live

      const URL = `${process.env.VUE_APP_API}/monitoring/update-time/`

      const body = {}
      const headers = { type: 'application/json' }
      const blob = new Blob([JSON.stringify(body)], headers)

      navigator.sendBeacon(`${URL + u}/${l}/${s}`, blob)
    },
    LoadEvent() {
      try {
        this.ActionLoadEvent({ event_slug: this.$route.params.sluglive })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style scoped>
  @media screen and (max-width: 480px) {
      #cloudflare{
        min-height: 240px;
      }
  }
  @media screen and (min-width: 480px) and (max-width: 720px) {
      #cloudflare{
        min-height: 350px;
      }
  }
  @media screen and (min-width: 720px) and (max-width: 900px) {
      #cloudflare{
        min-height: 470px;
      }
  }
  @media screen and (min-width: 900px) and (max-width: 1100px) {
      #cloudflare{
        min-height: 500px;
      }
  }
  @media screen and (min-width: 1100px) and (max-width: 1300px) {
      #cloudflare{
        min-height: 390px;
      }
  }
  @media screen and (min-width: 1300px) and (max-width: 1500px) {
      #cloudflare{
        min-height: 380px;
      }
  }
  @media screen and (min-width: 1500px) and (max-width: 1700px) {
      #cloudflare{
        min-height: 470px;
      }
  }
  @media screen and (min-width: 1700px) and (max-width: 1800px) {
      #cloudflare{
        min-height: 520px;
      }
  }
  @media screen and (min-width: 1800px) and (max-width: 2000px) {
      #cloudflare{
        min-height: 620px;
      }
  }
  @media screen and (min-width: 2000px) {
      #cloudflare{
        min-height: 620px;
      }
  }
  #InicioDoPlayer{
    color: red;
  }
  .collapse-title{
    font-size: 18px !important;
  }
  iframe{
    position: absolute !important;
    top: -400px !important;
  }
  #base{
    position: relative;
    max-width: 1599px;
    height: 950px;
  }
  #bgbtn btn-primary btn-block1 {
    display: grid;
    align-items: center;
    position: absolute;
    max-width: 1599px;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/animations/template_vazio_colmeia.png');
    background-repeat: no-repeat;
    background-size:contain;
    background-position:center top;
    z-index: 3;
  }
  /* #bg {
    position: absolute;
    max-width: 1599px;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/animations/tela.jpg');
    background-repeat: no-repeat;
    background-size:contain;
    background-position:center top;
    z-index: 1;
  } */
  #principal {
    display: grid;
    position: absolute;
    width: 35.7%;
    height: 35%;
    left: 0%;
    margin-top: 10.9%;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  #secundario {
    position: absolute;
    width: 65.5%;
    margin-left: 29.0%;
    margin-top: 11.1%;
    z-index: 2;
  }
  /* #sobras {
    position: absolute;
    width: 15%;
    background-color: #f8f8f8;
    left: 0%;
    height: 102%;
    margin-top: -57%;
    z-index: 5 !important;
  } */
  #controll{
    position: absolute;
    width: 100%;
    margin-left: 0%;
    margin-top: 55%;
    z-index: 4;
  }
  p, h1, h2, h3, h4{
    font-family: 'Montserrat', sans-serif;
  }
  #header{
    position: absolute;
    margin-left: 30%;
    margin-top: 4%;
    width: 100%;
    z-index: 3;
    font-size: 300%;
    color: white;
    font-weight: bold;
  }
  #sub-header{
    display: flexbox;
    position: fi;
    margin-left: 30.4%;
    margin-top: 8%;
    width: 100%;
    z-index: 3;
    font-size: 200%;
    color: white;
    font-weight: medium;
  }
  .fullScreen {
      width: 100% !important;
      height: 100%  !important;
      position: absolute  !important;
      top: 0  !important;
      left: 0  !important;
      margin: 0px !important;
  }
  .dark-layout{
    background-color: #161D31 !important;
  }
  #mask{
    z-index: 99999999;
  }
  .fullscreen-wrapper {
    width: 100%;
    height: 100%;
    background: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999999999;
  }
  .button {
    margin-bottom: 20px;
  }
  #controllToggle{
    position: absolute;
    display: flex;
    flex-direction:row;
    z-index: 9999999999999999;
    bottom: 0;
    width: 100%;
  }
  #title-text{
    max-width: 1000px;
    line-height: 1;
  }
</style>
